import React from 'react';

const Home = () => {
  return (
    <div>
      <p>.</p>
    </div>
  );
};

export default Home;
