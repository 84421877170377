import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function App(props) {
    const { lang_json, setting, error } = props.main
    const { register, handleSubmit, setError, errors } = useForm();

    const onSubmit = () => props.actionsMain.change_step(2);

    const handleChange = (event, group, index) => {
        const { name, value } = event.target;
        props.actionsMain.update_form(group, name, value, index);
    };

    useEffect(() => {
        if (error.key.name_field !== '' && error.key.page === 1) {
            setError(error.key.name_field, {type: "manual", message: error.msg, shouldFocus: true})
        }
    }, [error, setError]) 

    return (
        <div className="form form-one">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="anggota-form">
                    <div id="kapten" className="field-title">
                        <p>{lang_json.member_1}</p>
                    </div>
                    <div className="form-content team-name">
                        <p className="title-form">{lang_json.team_name} <span>*</span></p>
                        <p className="desc-form">{lang_json.team_name_desc}</p>
                        <input
                            type="text"
                            name="team_name"
                            className="input input__team-name"
                            defaultValue={
                                props.main.get.id !== null
                                    ? props.main.get.team_name
                                    : props.main.post.team_name
                            }
                            onChange={(event) =>
                                handleChange(event, 'team_name', null)
                            }
                            ref={register({ required: "Required" })}
                        />
                        {errors.team_name && <span className="error_message">{errors.team_name.message}</span>}
                    </div>

                    <div className="form-content leader-name">
                        <p className="title-form">{lang_json.full_name} <span>*</span></p>
                        <p className="desc-form">{lang_json.full_name_desc}</p>
                        <input
                            type="text"
                            name="member_name"
                            className="input input__leader-name"
                            defaultValue={
                                props.main.get.id !== null
                                    ? props.main.get.member[0].member_name
                                    : props.main.post.leader_fullname
                            }
                            onChange={(event) => handleChange(event, 'member', 0)}
                            ref={register({ required: "Required", pattern: { value: /^[a-zA-Z ]*$/, message: "Only alphabets" } })}
                        />
                        {errors.member_name && <span className="error_message">{errors.member_name.message}</span>}
                    </div>

                    { setting.is_need_identity &&
                    <div className="form-content leader-id">
                        <p className="title-form">{lang_json.id_number} <span>{ setting.is_need_identity && '*' }</span></p>
                        <p className="desc-form">{lang_json.id_number_desc}</p>
                        <input
                            type="number"
                            name="idCard_number_1"
                            className="input input__leader-id"
                            defaultValue={
                                props.main.get.id !== null
                                    ? props.main.get.member[0].idCard_number
                                    : props.main.post.idCard_number
                            }
                            onChange={(event) => handleChange(event, 'member', 0)}
                            ref={register({ required: "Required", minLength: { value: 3, message: "Minimum 3 digits" }, maxLength: { value: 30, message: "Maximum 30 digits" }, pattern: { value: /^[0-9]*$/, message: "Only numeric" } })}
                        />
                        {errors.idCard_number_1 && <span className="error_message">{errors.idCard_number_1.message}</span>}
                    </div>
                    }

                    <div className="form-content leader-phone">
                        <p className="title-form">{lang_json.phone} <span>*</span></p>
                        <p className="desc-form">{lang_json.phone_desc}</p>
                        <input
                            type="number"
                            name="phone_number"
                            className="input input__leader-phone"
                            defaultValue={
                                props.main.get.id !== null
                                    ? props.main.get.phone_number
                                    : props.main.post.phone_number
                            }
                            onChange={(event) =>
                                handleChange(event, 'phone_number', null)
                            }
                            ref={register({ required: "Required", minLength: { value: 8, message: "Minimum 8 digits" }, maxLength: { value: 15, message: "Maximum 15 digits" }, pattern: { value: /^[0-9]*$/, message: "Only numeric" } })}
                        />
                        {errors.phone_number && <span className="error_message">{errors.phone_number.message}</span>}
                    </div>

                    <div className="form-content leader-email">
                        <p className="title-form">{lang_json.email} <span>*</span></p>
                        <p className="desc-form">{lang_json.email_desc}</p>
                        <input
                            type="email"
                            name="email"
                            className="input input__leaderEmail"
                            defaultValue={
                                props.main.get.id !== null
                                    ? props.main.get.email
                                    : props.main.post.email
                            }
                            onChange={(event) => handleChange(event, 'email', 1)}
                            ref={register({ required: "Required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Use the correct email format" } })}
                        />
                        {errors.email && <span className="error_message">{errors.email.message}</span>}
                    </div>

                    <div className="form-content discord">
                        <p className="title-form">{lang_json.discord} <span>*</span></p>
                        <p className="desc-form">{lang_json.discord_desc}</p>
                        <img
                            className="desc-img"
                            src="https://lh3.googleusercontent.com/-WR4HaoDxoOc/XoMPHmBZIEI/AAAAAAAAAz4/W7bPhKv0QaodGjw1kAxCPvl445bJZbzuQCK8BGAsYHg/s0/2020-03-31.png"
                            alt="example"
                        />

                        <input
                            type="text"
                            name="discord"
                            className="input input__discord"
                            defaultValue={
                                props.main.get.id !== null
                                    ? props.main.get.discord
                                    : props.main.post.discord
                            }
                            onChange={(event) => handleChange(event, 'discord', null)}
                            ref={register({ required: "Required", pattern: { value: /[#]/, message: "Use the correct discord format" } })}
                        />
                        {errors.discord && <span className="error_message">{errors.discord.message}</span>}
                    </div>

                    <div className="form-content leader-nickname">
                        <p className="title-form">{lang_json.nickname} <span>*</span></p>
                        <p className="desc-form">{lang_json.nickname_desc}</p>
                        <input
                            type="text"
                            name="member_nickname"
                            className="input input__leader-nickname"
                            defaultValue={
                                props.main.get.id !== null
                                  ? (props.main.get.member[0].member_nickname && 
                                    decodeURIComponent(props.main.get.member[0].member_nickname))
                                  : (props.main.login.codm_nickname && 
                                    decodeURIComponent(props.main.login.codm_nickname))
                              }
                            readOnly
                        />
                    </div>

                    <div className="form-content leader-openid">
                        <p className="title-form">{lang_json.open_id} <span>*</span></p>
                        <p className="desc-form">{lang_json.open_id_desc}</p>
                        <img
                            className="openid-img"
                            src={lang_json.open_id_image}
                            alt=""
                        />
                        <input
                            type="number"
                            name="member_t_open_id_1"
                            className="input input__leader-openid"
                            defaultValue={
                                props.main.get.id !== null
                                    ? props.main.get.member[0].member_t_open_id
                                    : props.main.login.t_open_id
                            }
                            readOnly
                        />
                    </div>

                    {error.key.page === 0 && ( <p className="error">{error.msg}</p> )}
                    <button className="button next" type="Submit"></button>
                    <p className="notes">*{lang_json.notes}</p>
                </div>
            </form>
        </div>
    );
}