const main_state = {
  login: false,
  loader: false,
  lang_json: false,
  step: 1,
  setting: false,
  get: {
    id: null,
    team_name: '',
    phone_number: '',
    email: '',
    discord: '',
    leader_nickname: '',
    venue: '',
    member: [
      {
        id: null,
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
      {
        id: null,
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
      {
        id: null,
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
      {
        id: null,
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
      {
        id: null,
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
    ],
  },
  post: {
    team_name: '',
    leader_fullname: '',
    idCard_number: '',
    phone_number: '',
    email: '',
    discord: '',
    venue: '',
    member: [
      {
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
      {
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
      {
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
      {
        member_name: '',
        idCard_number: '',
        member_nickname: '',
        member_t_open_id: '',
      },
    ],
  },
  error: {
    key: {
      name_field: '',
      is_correct: true,
      page: 0,
    },
    msg: ""
  }
};

const main = (state = main_state, action) => {
  switch (action.type) {
    case 'GET_LOGIN':
      return { ...state, login: action.data };
    case 'GET_TRANSLATE':
      return { ...state, lang_json: action.data };
    case 'TOGGLE_LOADER':
      return { ...state, loader: action.data };
    case 'CHANGE_STEP':
      return { ...state, step: action.data };
    case 'ERROR_MSG':
      return { ...state, error: action.data };
    case 'GET_SETTING':
      return { ...state, setting: action.data};
    case 'GET_FORM':
      return { ...state, get: action.data };
    case 'UPDATE_FORM':
      if (state.get.id !== null) {
        if (action.group === 'member') {
          let member = [...state.get.member];
          member[action.memberIndex] = {
            ...state.get.member[action.memberIndex],
            [action.name]: action.value,
          };

          return {
            ...state,
            get: { ...state.get, member },
          };
        } else {
          return {
            ...state,
            get: { ...state.get, [action.name]: action.value },
          };
        }
      } else {
        if (action.memberIndex === 0) {
          if (action.name === 'member_name') {
            action.name = 'leader_fullname';
          }

          return {
            ...state,
            post: { ...state.post, [action.name]: action.value },
          };
        } else {
          if (action.group === 'member') {
            let member = [...state.post.member];
            member[action.memberIndex - 1] = {
              ...state.post.member[action.memberIndex - 1],
              [action.name]: action.value,
            };

            return {
              ...state,
              post: { ...state.post, member },
            };
          } else {
            return {
              ...state,
              post: { ...state.post, [action.name]: action.value },
            };
          }
        }
      }
    default:
      return state;
  }
};

export default main;
